<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Defect</strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
           
              <CInput
                label="Date Of Inspection"
                v-model="defect.dateOfInspection"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Date Of Inspection'"
                   
                    @blur="$v.defect.dateOfInspection.$touch()"
                                       

              >
             
              </CInput>
              <CInput
                label="Defect Description"
                v-model="defect.defectDescription"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Date Description'"
                   
                    @blur="$v.defect.defectDescription.$touch()">
             
              </CInput>
          
            <CInput
                label="Accessability"
                v-model="defect.accessibility"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Date Description'"
                   
                    @blur="$v.defect.accessibility.$touch()">
             
              </CInput>
              <CInput
                label="Status"
                v-model="defect.status"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Date Description'"
                   
                    @blur="$v.defect.status.$touch()">
          
              </CInput>
              <CInput
                label="Follow Up Due Date"
                v-model="defect.followUpDueDate"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Follow Up Due Date'"
                   
                    @blur="$v.defect.followUpDueDate.$touch()">
             
              </CInput>
              <CInput
                label="Follow Up Details"
                v-model="defect.followUpDetails"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Follow Up Details'"
                   
                    @blur="$v.defect.followUpDetails.$touch()">
             
              </CInput>
               <CInput
                label="Submitted By"
                v-model="defect.firstname "
                horizontal
               
                :disabled="mode.viewMode"
                 
                    @blur="$v.defect.firstname.$touch()"
             />
              <CInput
                label="Followed By"
                v-model="defect.followUpFirstName "
                horizontal
               
                :disabled="mode.viewMode"
                  
                    @blur="$v.defect.followUpFirstName.$touch()"
             />
            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Defect",
  data() {
    return {
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
     
     defectId:"",
      defect:{
      defectId:0,
      dateOfInspection:'',
      defectDescription:'',
      accessibility:'',
      status:'',
      followUpDueDate:'',
      followUpDetails:'',
      firstName:'',
      lastName:'',
      followUpFirstName:'',
      followUpLastName:'',

      
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    
    };
 },

   created() {  
             if (this.$route.params.mode == "new") {
                      this.mode.newMode = true;
                        this.mode.viewMode = false;
                        this.mode.editMode = false;
                        
                 }
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                   
                      this.defectId = this.$route.params.defectId;
                    this.getDefect();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
              
                 this.defectId = this.$route.params.defectId;
                 this.getDefect();
    }
        },
        
  methods: {
    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
   
             this.$router.push({ name: "Defects" });
    },
    

    
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    
    getDefect() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/defect/"+this.defectId)
        .then((response) => response.json())
        .then( (data)  => (this.defect = data) );
        
    },
  
  },
  mounted() {
     this. getDefect();
   
  }
};
</script>
